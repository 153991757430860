<template>
    <ul class="l-main-content main-content">
        <li id="home" class="l-section section section--is-active">
            <Home />
        </li>
        <li id="solucoes" class="l-section section">
            <Sistemas />
        </li>
        <li id="sobre" class="l-section section">
            <Sobre />
        </li>
        <li id="clientes" class="l-section section clients">
            <Clientes />
        </li>
        <li id="parceiros" class="l-section section clients">
            <Parceiros />
        </li>

        <li id="contato" class="l-section section">
            <Contato />
        </li>

        <li id="familia" class="l-section section">
            <Familia />
        </li>
        <li id="juntese" class="l-section section">
            <TrabalheConosco />
        </li>
        <li id="lgpd" class="l-section section">
          <Lgpd />
        </li>
    </ul>
</template>

<script>
import loadAnimation from '@/mixins/loadAnimation'
import hammer from '@/mixins/hammer'
import Clientes from "@/components/sections/Clientes.vue";
import Contato from "@/components/sections/Contato.vue";
import Familia from "@/components/sections/Familia.vue";
import Home from "@/components/sections/Home.vue";
import Parceiros from "@/components/sections/Parceiros.vue";
import Sistemas from "@/components/sections/Sistemas.vue";
import Sobre from "@/components/sections/Sobre.vue";
import TrabalheConosco from "@/components/sections/TrabalheConosco.vue";
import MenuLine from "@/components/MenuLine.vue";
import Menu from "@/components/Menu.vue";
import Header from "@/components/Header.vue";
import Lgpd from "@/components/sections/Lgpd.vue";
import CookieLgpd from "@/components/CookieLgpd.vue";
export default {
name: "HomeView",
    components: {
        Lgpd,
        Clientes,
        Contato,
        Familia,
        Home,
        Parceiros,
        Sistemas,
        Sobre,
        TrabalheConosco,
        Menu,
        MenuLine,
        Header,
        CookieLgpd,
    },
    props: {
      showMenu: {
        type: Boolean,
        default: true
      },
    },
    mixins: [loadAnimation, hammer],
    mounted () {
        this.startLoadAnimation()
        this.initHammer()
        if (this.showMenu) {
          document.querySelector('header').style.display='flex'
          document.querySelector('header').style.position='absolute'
          document.querySelector('.l-viewport').style.overflow='hidden'
          document.querySelector('body').style.overflow='hidden'
          document.querySelector('.l-viewport').style.background=''
          document.querySelector('.l-viewport').style.touchAction='none'
        }
    }
};
</script>
