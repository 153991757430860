export default {
    methods: {
        startLoadAnimation () {
            setTimeout(() => {
                var animations = [...document.querySelectorAll(".lottie-animation")];
                animations.forEach((element) => {
                    bodymovin.loadAnimation({
                        container: element,
                        renderer: "svg",
                        loop: true,
                        autoplay: true,
                        path: element.getAttribute("data-src"),
                    });
                    });
            }, 500);
        }
    }
}